import { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
// Functions
import { reportAPIs, fetchReportID } from "../api-endpoints/ReportEndpoints";
import { getImageUri } from "../../api/images/ImageAPI";

export function useReportStatus(reportParams) {
  const [loading, setLoading] = useState(true);
  const [reportStatus, setReportStatus] = useState({});
  const { CP, project, costReport, projectPhase } = reportParams;

  useEffect(() => {
    const reportParams = {
      CP: CP,
      project: project,
      costReport: costReport,
      projectPhase: projectPhase,
    };

    setLoading(true);
    getReportImportStatus(reportParams)
      .then((data) => {
        setLoading(false);
        setReportStatus(data);
      })
      .catch((e) => {});
  }, [CP, project, costReport, projectPhase]);

  return {
    reportStatus: {
      data: reportStatus,
      loading: loading,
      set: setReportStatus,
      get: getReportImportStatus,
    },
  };
}

// Get embedded report
export async function getReportsByReportId(reportParams) {
  const { CP, project, costReport, projectPhase } = reportParams;

  const reportId = fetchReportID(projectPhase);

  let stage,
    revision,
    post_contract_id = "",
    packageId = "",
    contractType = "";

  if (!isEmpty(CP)) {
    stage = CP.stage;
    revision = CP.version;
  }

  if (!isEmpty(costReport)) {
    post_contract_id = costReport.Id.toString();
    contractType = costReport.ContractType;
    packageId = costReport.PackageId;
  }

  let imageUri = "";
  try {
    imageUri = await getImageUri(project.imageId, "PROJECT");
  } catch {
    imageUri = "";
  }

  const payload = {
    ReportID: reportId,
    ProjectId: project.id.toString(),
    ProjectImage: imageUri,
    ProjectPhase: projectPhase,
    Stage: stage,
    Revision: revision,
    PostContractId: post_contract_id,
    ContractType: contractType,
    PackageId: packageId,
  };

  const param = "?param=" + window.btoa(JSON.stringify(payload));
  const url = reportAPIs().report_by_report_id + param;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    response = await response.json();
  } else {
    return [];
  }

  return response;
}

export async function getReportImportStart(reportParams) {
  const { CP, project, costReport, projectPhase } = reportParams;
  let stage, revision, post_contract_id, contractType, packageId;

  if (!isEmpty(CP)) {
    stage = CP.stage;
    revision = CP.version;
  }

  if (!isEmpty(costReport)) {
    post_contract_id = costReport.Id.toString();
    contractType = costReport.ContractType;
    packageId = costReport.PackageId;
  }

  let imageUri = "";
  try {
    imageUri = await getImageUri(project.imageId, "PROJECT");
  } catch {
    imageUri = "";
  }

  const payload = {
    ProjectId: project.id.toString(),
    ProjectImage: imageUri,
    ProjectPhase: projectPhase,
    Stage: stage,
    Revision: revision,
    PostContractId: post_contract_id,
    ContractType: contractType,
    PackageId: packageId,
  };

  const param = "?param=" + window.btoa(JSON.stringify(payload));

  const url = reportAPIs().report_import_start + param;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = {};
  try {
    response = await fetch(url, config);
  } catch (e) {
    // Service is down
    console.log("Report Service Error");
    return { down: true };
  }

  // Service is up
  if (response.ok) {
    response = await response.json();
  } else {
    console.log("ImportStart Error");
    response = {};
  }

  return response;
}

export async function getReportImportStatus(reportParams) {
  const { CP, project, costReport, projectPhase } = reportParams;

  let stage,
    revision,
    post_contract_id = "";

  if (!isEmpty(CP)) {
    stage = CP.stage;
    revision = CP.version;
  }

  if (!isEmpty(costReport)) {
    post_contract_id = costReport.Id.toString();
  }

  let imageUri = "";
  try {
    imageUri = await getImageUri(project.imageId, "PROJECT");
  } catch {
    imageUri = "";
  }

  const payload = {
    ProjectId: project.id.toString(),
    ProjectImage: imageUri,
    ProjectPhase: projectPhase,
    Stage: stage,
    Revision: revision,
    PostContractId: post_contract_id,
  };

  const param = "?param=" + window.btoa(JSON.stringify(payload));
  const url = reportAPIs().report_import_status + param;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = {};
  try {
    response = await fetch(url, config);
  } catch (e) {
    // Service is down
    console.log("Report Service Error");
    return { down: true };
  }

  // Service is up
  if (response.ok) {
    response = await response.json();
  } else {
    console.log("ImportStatus Error");
    response = {};
  }

  return response;
}
