import React, { useState } from "react";

import { determineSubLocation } from "../estimate-commentary/EstimateCommentary.functions";
import { hasRoles } from "../../utils/roles";

import "./OptionsAnalysisControl.css";
import isEmpty from "../../validation/is-empty";

export default function OptionsAnalysisControl(props) {
  const { project, user } = props;

  const { setModal } = props;

  const [isOpen, setIsOpen] = useState(false);

  let classType = isOpen
    ? "options-analysis-edit-button-clicked"
    : "options-analysis-edit-button";

  if (isEmpty(user)) {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <>
      <button
        id={"options-analysis-control-open-button"}
        className={classType}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="fas fa-cog"></i>
      </button>
      <Control
        project={project}
        user={user}
        isOpen={isOpen}
        setModal={setModal}
      />
    </>
  );
}

export function Control(props) {
  const { user } = props;
  const { isOpen } = props;

  const { setModal } = props;

  // Hide if not matching sublocation
  let subLocation = determineSubLocation();
  if (subLocation !== "options-analysis") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  const classType = isOpen
    ? "options-analysis-control"
    : "options-analysis-control-hidden";

  return (
    <div className={classType}>
      <div className="options-analysis-edit-wrapper">
        <button
          id={"button-add-options-analysis-baseline"}
          className="options-analysis-edit-button"
          onClick={() => {
            setModal(true);
          }}
          title="Add Options Baseline"
        >
          <i className="fas fa-chart-line"></i>
        </button>
      </div>
    </div>
  );
}
