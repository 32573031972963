import { useEffect, useState } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { bmAPIs } from "../api-endpoints/BenchmarkingEndpoints";
import isEmpty from "../../validation/is-empty";

export function useBenchmarkWithRows(benchmarkId) {
  const [loading, setLoading] = useState(true);
  const [benchmarks, setBenchmarks] = useState({});

  useEffect(() => {
    if (benchmarkId) {
      setLoading(true);
      getBenchmarkWithRows(benchmarkId)
        .then((res) => {
          setBenchmarks(res);
          setLoading(false);
        })
        .catch((e) => {
          console.log("Error" + e.message);
          setLoading(false);
        });
    }
  }, [benchmarkId]);

  return {
    benchmarkWithRows: {
      data: benchmarks,
      loading: loading,
      set: setBenchmarks,
      get: getBenchmarkWithRows,
    },
  };
}

// Get Detail for Selected Benchmarks
export async function getBenchmarkWithRows(benchmarkId) {
  const url = bmAPIs().read_benchmarks_complete;
  const payload = {
    BenchmarkIds: [benchmarkId],
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = {};
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getBenchmarksWithRows Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
    response = response.data;
  } else {
    response = [];
  }

  return response;
}

// Get Detail for Selected Benchmarks
export async function getBenchmarksWithRows(benchmarkIDs) {
  const url = bmAPIs().read_benchmarks_complete;
  const payload = {
    BenchmarkIds: benchmarkIDs.map((b) => {
      return b.benchmark_id.toString();
    }),
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = {};
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getBenchmarksWithRows Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
    response = response.data;
  } else {
    response = [];
  }

  return response;
}

export function useBenchmarksWithRows(benchmarkIDs) {
  const [loading, setLoading] = useState(true);
  const [benchmarksWithRows, setBenchmarksWithRows] = useState([]);

  useEffect(() => {
    if (!isEmpty(benchmarkIDs)) {
      setLoading(true);
      getBenchmarksWithRows(benchmarkIDs)
        .then((benchmarks) => {
          setBenchmarksWithRows(benchmarks);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [benchmarkIDs]);

  return {
    loading,
    benchmarksWithRows,
    setBenchmarksWithRows,
    error: null,
  };
}

export function useSensitivityLevelCodes() {
  const [loading, setLoading] = useState(true);
  const [sensitivityLevelCodes, setSensitivityLevelCodes] = useState({});
  useEffect(() => {
    setLoading(true);
    getSensitivityLevelCodes()
      .then((res) => {
        setSensitivityLevelCodes(res);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    sensitivityLevelCodes: {
      data: sensitivityLevelCodes.data,
      loading: loading,
      set: setSensitivityLevelCodes,
      get: getSensitivityLevelCodes,
    },
  };
}

export async function getSensitivityLevelCodes() {
  const url = bmAPIs().get_benchmarks_sensitivity_level;
  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const SENSITIVITYLEVELINFO = await response.json();

    return SENSITIVITYLEVELINFO;
  } else {
    response = {};
  }
  return response;
}

