import { useState, useEffect } from "react";
import {
  getProjectDetailByUser,
  getPortfolioProjects,
  getProjectsCostPlanning,
  getProjectsPostContract,
} from "../projects/ProjectAPI";

import isEmpty from "../../validation/is-empty";

// Project Selection ONLY
export function usePortfolioProjectSelection(show) {
  const [projectsLoading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (show) {
      setLoading(true);
      getProjectDetailByUser()
        .then((projectsRes) => {
          setProjects(projectsRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [show]);

  return {
    projects,
    setProjects,
    projectsLoading,
    error: null,
  };
}

export function usePortfolioCostPlanning(projects, selectedPortfolio) {
  const [projectsCostPlanningLoading, setLoading] = useState(true);
  const [projectsCostPlanning, setProjectsCostPlanning] = useState([]);

  useEffect(() => {
    if (isEmpty(projects)) {
      return;
    }
    const controller = new AbortController();
    const abortSignal = controller.signal;

    setLoading(true);
    getProjectsCostPlanning(projects, selectedPortfolio, abortSignal)
      .then((projectsRes) => {
        if (!abortSignal.aborted) {
          setLoading(false);
          setProjectsCostPlanning(projectsRes);
        }
      })
      .catch((e) => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [projects, selectedPortfolio]);

  return {
    projectsCostPlanning,
    setProjectsCostPlanning,
    projectsCostPlanningLoading,
    error: null,
  };
}

export function usePortfolioPostContract(projects) {
  const [projectsPostContractLoading, setLoading] = useState(true);
  const [projectsPostContract, setProjectsPostContract] = useState([]);

  useEffect(() => {
    if (isEmpty(projects)) {
      return;
    }
    const controller = new AbortController();
    const abortSignal = controller.signal;

    setLoading(true);
    getProjectsPostContract(projects, abortSignal)
      .then((projectsRes) => {
        if (!abortSignal.aborted) {
          setLoading(false);
          setProjectsPostContract(projectsRes);
        }
      })
      .catch((e) => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [projects]);

  return {
    projectsPostContract,
    setProjectsPostContract,
    projectsPostContractLoading,
    error: null,
  };
}

export function usePortfolioProjects(portfolioID) {
  const [projectsLoading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const abortSignal = controller.signal;

    setLoading(true);
    if (portfolioID === "all-projects") {
      setLoading(true);
      getProjectDetailByUser(abortSignal)
        .then((res) => {
          if (!abortSignal.aborted) {
            setLoading(false);
            setProjects(res);
          }
        })
        .catch((e) => {
          console.log(e.message);
          setLoading(false);
        });
    } else {
      getPortfolioProjects(portfolioID, abortSignal)
        .then((res) => {
          if (!abortSignal.aborted) {
            setLoading(false);
            setProjects(res);
          }
        })
        .catch((e) => {
          console.log(e.message);
          setLoading(false);
        });
    }
  }, [portfolioID]);

  return {
    projects,
    setProjects,
    projectsLoading,
    error: null,
  };
}
