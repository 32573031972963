export function postContractFormTable(field, fields, setFields, display) {
  const headingsRow = [];
  const tableRow = [];

  // Headings
  const headings = Array.from(
    new Set(
      field.options
        .map((value) => value.options)
        .map((value) => value.map((x) => x.name))
        .flat()
    )
  );

  headings.forEach((heading) => {
    headingsRow.push(<div className="pcf-element-item">{heading}</div>);
  });

  field.options.forEach((row) => {
    const fieldRow = [];

    row.options.forEach((cell) => {
      if (cell.dataType === "text") {
        fieldRow.push(
          <input
            key={cell.id + cell.name}
            id={"pcf-table-text-" + cell.id}
            className="pcf-element-item"
            value={cell.value}
            onChange={(e) => {
              let temp = [...fields];
              let inputTable = findById(temp, field.id);
              let inputRow = findById(inputTable.options, row.id);
              let inputCell = findById(inputRow.options, cell.id);

              inputCell.value = e.target.value;
              setFields(temp);
            }}
          ></input>
        );
      }

      if (cell.dataType === "dropdown") {
        fieldRow.push(
          <div className="pcf-element" key={cell.id + cell.name}>
            <select
              id={"pcf-table-dropdown-" + cell.id}
              className="pcf-element-item"
              value={cell.value}
              onChange={(e) => {
                let temp = [...fields];
                let inputTable = findById(temp, field.id);
                let inputRow = findById(inputTable.options, row.id);
                let inputCell = findById(inputRow.options, cell.id);

                inputCell.value = e.target.value;
                setFields(temp);
              }}
            >
              {cell?.options?.map((option) => {
                return (
                  <option key={option.key} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
        );
      }
    });

    tableRow.push(
      <div className="general-row-container" key={row.id}>
        {fieldRow}
        <button
          id={"pcf-table-delete-" + row.id}
          key={row.id + "-delete-button"}
          onClick={() => {
            // Remove a row from the table
            let temp = [...fields];
            let inputTable = temp.find((f) => f.id === field.id);
            inputTable.options = inputTable.options.filter(
              (r) => r.id !== row.id
            );
            setFields(temp);
          }}
        >
          Delete
        </button>
      </div>
    );
  });

  display.push(
    <div>
      <div className="general-row-container">{headingsRow}</div>
      <div>{tableRow}</div>
      <div className="general-row-container">
        <button
          id={"pcf-table-add-button"}
          className="general-upload-button"
          onClick={() => {
            // Add a new row to the table
            let temp = [...fields];
            let inputTable = temp.find((f) => f.id === field.id);
            let inputRow = JSON.parse(JSON.stringify(inputTable.options[0]));
            inputRow.id = inputTable.options.length + 1;
            inputRow.options.forEach((value) => {
              if (value.dataType === "dropdown") {
                value.value = value.options[0].value;
              } else {
                value.value = "";
              }
            });
            inputTable.options.push(inputRow);
            setFields(temp);
          }}
        >
          Add {field.name}
        </button>
      </div>
    </div>
  );
}

function findById(array, findKey) {
  return array.find((x) => x.id === findKey);
}
