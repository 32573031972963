import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { translate, tranStr } from "../../utils/translation";
import {
  deepClone,
  nameof,
  toPascalCase,
  areArraysOfObjectsEqual,
} from "../../helpers/common.functions";
import { captureScreenshot } from "../../helpers/canvas.functions";

import {
  useLocations,
  useLocationFactors,
} from "../../api/admin/RegionManagementAPI";
import { useBenchmarking } from "../../api/benchmarking-control/BenchmarkingControlAPI";
import { getBenchmarksWithRows } from "../../api/benchmarking/BenchmarkingAPI";
import {
  addPortfolioForBenchmarkAnalysis,
  getPortfoliosOfBenchmarkAnalysis,
  deletePortfolioOfBenchmarkAnalysis,
} from "../../api/portfolio/PortfolioBenchmarkAnalysisAPI";

import {
  sectorList,
  subsectorList,
  qualityList,
  typeList,
  currencyList,
  unitOfMeasureList,
} from "../project-create/CreateProject.functions";

import { CONTROL_TRIGGER_TYPES } from "../master/MasterPage";

import { MASTER_BENCHMARKING } from "./BenchmarkCreate.strings";

import BenchmarkCreateAnalysisPersistence from "./BenchmarkCreateAnalysisPersistence.class";
import BenchmarkCreateAnalysisConfiguration from "./BenchmarkCreateAnalysisConfiguration.class";

import BenchmarkCreateConfigurationModal from "./BenchmarkCreateConfigurationModal";
import BenchmarkCreateSelectionModal from "./BenchmarkCreateSelectionModal";
import BenchmarkCreateAnalysisGate from "./BenchmarkCreateAnalysisGate";
import BenchmarkCreateAnalysisWelcome from "./BenchmarkCreateAnalysisWelcome";
import BenchmarkCreateLoadOrSaveModal from "./BenchmarkCreateLoadOrSaveModal";
import BenchmarkMiniTable from "./BenchmarkMiniTable";
import { hasRoles } from "../../utils/roles";

const UNIT_OF_MEASURE_DEFAULT = "m²";

const FEATURES = {
  START_OVER: { isEnabled: true },
  SCREENSHOT: { isEnabled: false },
  REFRESH: { isEnabled: true },
  DASHBOARD: { isEnabled: true },
};

/// props validation | SQ(javascript:S6774)
useBenchmarkCreateConfigurationControls.propTypes = {
  user: PropTypes.object,
};
///
export function useBenchmarkCreateConfigurationControls({ user }) {
  const [showConfigure, setShowConfigure] = useState(false);
  const [showSelectProjects, setShowSelectProjects] = useState(false);
  const [showLoadOrSave, setShowLoadOrSave] = useState(false);

  const [sector, setSector] = useState("");
  const [subsector, setSubsector] = useState("");
  const [quality, setQuality] = useState("");
  const [projectType, setProjectType] = useState("");
  const [currency, setCurrency] = useState("");
  const [unitOfMeasure, setUnitOfMeasure] = useState(UNIT_OF_MEASURE_DEFAULT);
  const [currencyFactor, setCurrencyFactor] = useState(false);
  const [isLocalRegionFactorEnabled, setIsLocalRegionFactorEnabled] =
    useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");

  const { locations } = useLocations(true);
  const { locationFactors } = useLocationFactors();

  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [baseYear, setBaseYear] = useState("");
  const [latitude, setLatitude] = useState("0");
  const [longitude, setLongitude] = useState("0");
  const [showDashboard, setShowDashboard] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { benchmarking: benchmarks, setBenchmarking: setBenchmarks } =
    useBenchmarking(user);

  const [benchmarkSelectionFilters, setBenchmarkSelectionFilters] = useState(
    {}
  );
  const [benchmarkSelection, setBenchmarkSelection] = useState([]);
  const [selectedBenchmarkIds, setSelectedBenchmarkIds] = useState([]);
  const [regionCountryList, setRegionCountryList] = useState([]);

  useEffect(() => {
    if (!isEmpty(locations.data) && !isEmpty(benchmarkSelection)) {
      const LFI = locations.data;
      const currentRegionCountryList = [
        { region, country },
        ...getBenchmarksRegionCountryList(benchmarkSelection, LFI),
      ];

      if (
        !areArraysOfObjectsEqual(regionCountryList, currentRegionCountryList)
      ) {
        setRegionCountryList(currentRegionCountryList);
      }
    }
  }, [region, country, benchmarkSelection, locations, regionCountryList]);

  const [error, setError] = useState(null);

  const [isGenerated, setIsGenerated] = useState(false);

  const isClient = hasRoles(user.roles, ["Client"]);

  function clearError() {
    setError(<></>);
  }

  // used with Start Over
  function resetUserChoices() {
    setSector("");
    setSubsector("");
    setQuality("");
    setProjectType("");
    setCurrency("");
    setUnitOfMeasure(UNIT_OF_MEASURE_DEFAULT);
    setCurrencyFactor(false);
    setIsLocalRegionFactorEnabled(false);
    setCurrencySymbol("");

    setRegion("");
    setCountry("");
    setCity("");
    setBaseYear("");
    setLatitude("0");
    setLongitude("0");

    setBenchmarkSelectionFilters({});
    setBenchmarkSelection([]);
    setSelectedBenchmarkIds([]);

    setError([]);

    setIsGenerated(false);
  }

  // handle onSave on BenchmarkCreateConfigurationModal
  // see BenchmarkCreateConfigurationModal > SaveForm > onClick
  function onSaveBenchmarkCreateConfigurationModal(e, forms) {
    const success = validateConfiguration(forms);

    return success;
  }

  function validateConfiguration(forms) {
    clearError();

    const { isValid, emptyFields } = isFormsValid(forms);

    if (!isValid) {
      setError(
        <>
          <div>{MASTER_BENCHMARKING.WARNINGS.REQUIRED_FIELDS_MISSING}</div>
          <div>{emptyFields.join(", ")}</div>
        </>
      );

      return false;
    }

    return true;
  }

  const isConfigurationComplete = () =>
    isFormsValid(controls?.find((c) => c.key === "configure")?.forms).isValid;

  function isFormsValid(forms) {
    if (!forms) {
      return { isValid: false };
    }

    const emptyFields = [];

    for (const form of forms) {
      for (const formControl of form.formControls) {
        const { name, isRequired, value } = formControl;
        if (isRequired && isEmpty(value)) {
          emptyFields.push(name);
        }
      }
    }

    return { isValid: isEmpty(emptyFields), emptyFields };
  }

  // handle onSave on BenchmarkCreateSelectionModal
  // see BenchmarkCreateSelectionModal > SaveForm > onClick
  function onSaveBenchmarkCreateSelectionModal(e, forms) {
    const success = validateBenchmarkSelection();

    if (success) {
      refreshSelectedBenchmarkIds();
    }

    return success;
  }

  // atleast 2 benchmarks need to be selected for this to be true
  const isBenchmarkSelectionComplete = () => benchmarkSelection.length > 1;

  function validateBenchmarkSelection() {
    clearError();

    if (isEmpty(benchmarkSelection) || !isBenchmarkSelectionComplete()) {
      setError(
        <div>{MASTER_BENCHMARKING.WARNINGS.REQUIRED_BENCHMARKS_MISSING}</div>
      );
      return false;
    }

    return true;
  }

  // refresh selectedBenchmarkIds when [benchmarkSelection] changes
  async function refreshSelectedBenchmarkIds() {
    const benchmarkIds = benchmarkSelection.map((s) => ({
      benchmark_id: s.id,
    }));

    setSelectedBenchmarkIds(benchmarkIds);
  }

  const isGenerateReady = () =>
    isConfigurationComplete() && isBenchmarkSelectionComplete();

  const getConfiguration = () =>
    new BenchmarkCreateAnalysisConfiguration({
      sector,
      subsector,
      quality,
      projectType,
      currency,
      unitOfMeasure,
      currencySymbol,
      region,
      country,
      city,
      baseYear,
      latitude,
      longitude,
      currencyFactor,
      isLocalRegionFactorEnabled,
    });

  const getConfigurationSetters = () => ({
    setSector,
    setSubsector,
    setQuality,
    setProjectType,
    setCurrency,
    setUnitOfMeasure,
    setCurrencySymbol,
    setRegion,
    setCountry,
    setCity,
    setBaseYear,
    setLatitude,
    setLongitude,
    setCurrencyFactor,
    setIsLocalRegionFactorEnabled,
  });

  const getCurrentSelection = () =>
    new BenchmarkCreateAnalysisPersistence({
      user,
      configuration: getConfiguration(),
      selectedBenchmarkIds,
      benchmarkSelection,
      isConfigurationComplete: isConfigurationComplete(),
      isBenchmarkSelectionComplete: isBenchmarkSelectionComplete(),
      isGenerateReady: isGenerateReady(),
    });

  async function loadAnalysisItem({ item }) {
    // here item is of type BenchmarkCreateAnalysisPersistence
    const { configuration, selectedBenchmarkIds } = item;

    // apply all configuration parameters (setSector, setRegion etc.)
    const setters = getConfigurationSetters();
    for (const [key, value] of Object.entries(configuration)) {
      const setter = "set" + toPascalCase(key); // assess name of setter
      const func = setters[setter]; // capture corresponding setter function

      if (typeof func === "function") {
        func(value); // apply setter
      }
    }

    // capture benchmark selection
    const benchmarkIds = getBenchmarkIds({ selectedBenchmarkIds });
    const benchmarkSelection = await getBenchmarksWithRows(benchmarkIds);

    // apply benchmark selection
    setSelectedBenchmarkIds(benchmarkIds);
    setBenchmarkSelection(benchmarkSelection);
  }

  const controls = [
    {
      key: "start_over",
      label: translate("Clear All"),
      get className() {
        return FEATURES.START_OVER.isEnabled && isGenerated
          ? "reset"
          : "hidden";
      },
      type: CONTROL_TRIGGER_TYPES.LOAD_PAGE,
      PageComponent: BenchmarkCreateAnalysisWelcome,
      // component props
      // props to MasterPage
      separatorAfter: true,
      onBeforeOpen: resetUserChoices,
    },
    {
      key: "screenshot",
      label: translate("Save Image"),
      get className() {
        return FEATURES.SCREENSHOT.isEnabled && isGenerated
          ? "action"
          : "hidden";
      },
      type: CONTROL_TRIGGER_TYPES.TRIGGER_ACTION,
      // props to MasterPage
      separatorAfter: true,
      onTrigger: takeScreenshot,
    },
    {
      key: "dashboard",
      label: translate("Benchmark Dashboard"),
      get className() {
        return FEATURES.DASHBOARD.isEnabled && isGenerated ? "" : "hidden";
      },
      type: CONTROL_TRIGGER_TYPES.LOAD_PAGE,
      PageComponent: BenchmarkCreateAnalysisGate,
      // component props
      // props to MasterPage
      onBeforeOpen: () => {
        setIsGenerated(true);
        setShowDashboard(true);
      },
      // props to BenchmarkCreateAnalysisGate
      get configurationComplete() {
        return isConfigurationComplete();
      },
      get benchmarkSelectionComplete() {
        return isBenchmarkSelectionComplete();
      },
      // props to BenchmarkCreateAnalysis
      title: "",
      get configuration() {
        return getConfiguration();
      },
      LFI: locationFactors?.data,
      selectedBenchmarkIds,
      benchmarkSelectionFilters,
      regionCountryList,
      // props to BenchmarkCreateAnalysisDisclaimer
      disclaimerDisplay: {
        fields: [
          ["Project Function", subsector],
          ["Project Type", projectType],
          ["Quality Level", quality],
          ["Location", city],
          ["Escalation Year", baseYear],
          ["Currency", currency],
        ],
        fallbackValue: "-",
      },
      moduleId: selectedItemId,
      showDashboard,
      setShowDashboard,
      user
    },
    {
      key: "configure",
      label: translate("Configure"),
      className: "",
      type: CONTROL_TRIGGER_TYPES.OPEN_MODAL,
      show: showConfigure,
      setShow: setShowConfigure,
      ModalComponent: BenchmarkCreateConfigurationModal,
      onBeforeOpen: clearError,
      // modal component props
      // props to Form within BenchmarkCreateConfigurationModal
      forms: getFormsForBenchmarkCreateAnalysisConfiguration({
        configuration: getConfiguration(),
        configurationSetters: getConfigurationSetters(),
        locations,
      }),
      // props to SaveForm within BenchmarkCreateConfigurationModal
      onSave: onSaveBenchmarkCreateConfigurationModal,
      error,
      user,
    },
    {
      key: "select_projects",
      label: translate("Select Projects"),
      className: "",
      type: CONTROL_TRIGGER_TYPES.OPEN_MODAL,
      show: showSelectProjects,
      setShow: setShowSelectProjects,
      ModalComponent: BenchmarkCreateSelectionModal,
      onBeforeOpen: clearError,
      // modal component props
      benchmarks,
      setBenchmarks,
      benchmarkSelectionFilters,
      setBenchmarkSelectionFilters,
      benchmarkSelection,
      setBenchmarkSelection,
      onSave: onSaveBenchmarkCreateSelectionModal,
      error,
      user,
    },
    {
      key: "generate",
      get label() {
        return FEATURES.REFRESH.isEnabled && isGenerated
          ? translate("Refresh")
          : translate("Generate");
      },
      get className() {
        return isGenerateReady() ? "action" : "unavailable";
      },
      type: CONTROL_TRIGGER_TYPES.LOAD_PAGE,
      PageComponent: BenchmarkCreateAnalysisGate,
      // component props
      // props to MasterPage
      onBeforeOpen: () => setIsGenerated(true),
      // props to BenchmarkCreateAnalysisGate
      get configurationComplete() {
        return isConfigurationComplete();
      },
      get benchmarkSelectionComplete() {
        return isBenchmarkSelectionComplete();
      },
      // props to BenchmarkCreateAnalysis
      title: "",
      get configuration() {
        return getConfiguration();
      },
      LFI: locationFactors?.data,
      selectedBenchmarkIds,
      benchmarkSelectionFilters,
      regionCountryList,
      // props to BenchmarkCreateAnalysisDisclaimer
      disclaimerDisplay: {
        fields: [
          ["Project Function", subsector],
          ["Project Type", projectType],
          ["Quality Level", quality],
          ["Location", city],
          ["Escalation Year", baseYear],
          ["Currency", currency],
        ],
        fallbackValue: "-",
      },
      moduleId: selectedItemId,
      showDashboard,
      setShowDashboard,
      user
    },
    {
      key: "load_or_save",
      label: translate("Save / Load"),
      className: "",
      type: CONTROL_TRIGGER_TYPES.OPEN_MODAL,
      show: showLoadOrSave,
      setShow: setShowLoadOrSave,
      ModalComponent: BenchmarkCreateLoadOrSaveModal,
      // props to BenchmarkCreateLoadOrSaveModal
      user,
      get currentSelection() {
        return getCurrentSelection();
      },
      loadAnalysisItem,
      selectedItemId,
      setSelectedItemId,
    },
  ];

  const deletableKeys = ["select_projects", "configure"];
  if(isClient){
    return controls.filter(item=> !deletableKeys.includes(item.key));
  }

  return controls;
}

export function getFormsForBenchmarkCreateAnalysisConfiguration({
  configuration,
  configurationSetters,
  locations,
} = {}) {
  const {
    sector,
    subsector,
    quality,
    projectType,
    currency,
    unitOfMeasure,
    region,
    country,
    city,
    baseYear,
    latitude,
    longitude,
    currencyFactor,
    isLocalRegionFactorEnabled,
  } = configuration;

  const {
    setSector,
    setSubsector,
    setQuality,
    setProjectType,
    setCurrency,
    setUnitOfMeasure,
    setRegion,
    setCountry,
    setCity,
    setBaseYear,
    setLatitude,
    setLongitude,
    setCurrencyFactor,
    setIsLocalRegionFactorEnabled,
  } = configurationSetters || {};

  const forms = [
    {
      key: "report_details",
      label: translate("Report Details"),
      icon: "far fa-file-alt",
      // form fields
      formControls: [
        {
          name: "Project Sector",
          isRequired: false,
          component: "Select",
          key: nameof({ sector }),
          label: translate("Project Sector"),
          placeholder: tranStr("Project Sector"),
          value: sector,
          set: setSector,
          options: sectorList(),
          onChange: setSector,
        },
        {
          name: "Project Subsector",
          isRequired: false,
          component: "SelectFilter",
          key: nameof({ subsector }),
          label: translate("Project Subsector"),
          placeholder: tranStr("Project Subsector"),
          value: subsector,
          set: setSubsector,
          options: subsectorList(),
          onChange: setSubsector,
          filter: sector,
        },
        {
          name: "Project Quality",
          isRequired: false,
          component: "Select",
          key: nameof({ quality }),
          label: translate("Project Quality"),
          placeholder: tranStr("Project Quality"),
          value: quality,
          set: setQuality,
          options: qualityList(),
          onChange: setQuality,
        },
        {
          name: "Project Type",
          isRequired: false,
          component: "Select",
          key: nameof({ projectType }),
          label: translate("Project Type"),
          placeholder: tranStr("Project Type"),
          value: projectType,
          set: setProjectType,
          options: typeList(),
          onChange: setProjectType,
        },
        {
          name: "Currency",
          isRequired: false,
          component: "Select",
          key: nameof({ currency }),
          label: translate("Currency"),
          placeholder: tranStr("Currency"),
          value: currency,
          set: setCurrency,
          options: currencyList(),
          onChange: setCurrency,
        },
        {
          name: "Displayed Unit",
          isRequired: false,
          component: "Select",
          key: nameof({ unitOfMeasure }),
          label: translate("Displayed Unit"),
          placeholder: tranStr("Displayed Unit"),
          value: unitOfMeasure,
          set: setUnitOfMeasure,
          options: unitOfMeasureList(),
          onChange: setUnitOfMeasure,
          icon: "fas fa-question-circle",
          iconToolTip: MASTER_BENCHMARKING.TOOLTIPS.DISPLAYED_UNIT,
        },
      ],
    },
    {
      key: "define_parameters",
      label: translate("Parameters"),
      icon: "fas fa-sliders-h",
      // form fields
      formControls: [
        {
          name: "Region",
          isRequired: true,
          component: "RegionSelect",
          key: nameof({ region }),
          label: translate("Region"),
          value: region,
          set: setRegion,
          options: locations?.data,
          classType: "create-project-location-select",
          setCountry: setCountry,
          setCity: setCity,
        },
        {
          name: "Country",
          isRequired: true,
          component: "CountrySelect",
          key: nameof({ country }),
          label: translate("Country"),
          value: country,
          set: setCountry,
          options: locations?.data,
          classType: "create-project-location-select",
          region: region,
          setCity: setCity,
        },
        {
          name: "City",
          isRequired: true,
          component: "CitySelect",
          key: nameof({ city }),
          label: translate("City"),
          value: city,
          set: setCity,
          options: locations?.data,
          classType: "create-project-location-select",
          country: country,
        },
        {
          name: "Escalation Year",
          isRequired: false,
          component: "Input",
          key: nameof({ baseYear }),
          label: translate("Escalation Year"),
          value: baseYear,
          set: setBaseYear,
          validationType: "numberonly",
          attributes: {
            placeholder: "YYYY",
            pattern: "[1900-2999]{4}",
            minLength: 4,
            maxLength: 4,
          },
        },
        {
          name: "Latitude / Longitude",
          isRequired: false,
          component: "CreateProjectMapWithCoordinateInputs",
          key: "latitude_longitude",
          label: "Latitude / Longitude",
          get value() {
            return latitude && longitude ? [latitude, longitude] : null;
          },
          set: [setLatitude, setLongitude],
        },
      ],
    },
    {
      key: "select_metrics",
      label: translate("Metrics"),
      icon: "fas fa-ruler",
      // form fields
      formControls: [
        {
          name: "Metrics",
          isRequired: false,
          component: "Text",
          key: "text_default_metrics",
          value: (
            <>
              <p>The following metrics are selected as default:</p>
              <ul className="text-left">
                <li>Total Number of Floors</li>
                <li>Local Region Area</li>
                <li>Total Construction Cost</li>
                <li>Total Project Cost</li>
                <li>Construction Cost per GIA</li>
                <li>Total Building Services Cost per GIA</li>
              </ul>
            </>
          ),
        },
      ],
    },
    {
      key: "global_benchmarking",
      isHeadless: true,
      className: "grid-full-width",
      // form fields
      formControls: [
        {
          name: "Global Benchmarking",
          isRequired: false,
          component: "Toggle",
          key: "currency_factor",
          label: translate("Global Benchmarking"),
          value: currencyFactor,
          set: setCurrencyFactor,
          icon: "fas fa-question-circle",
          iconToolTip: MASTER_BENCHMARKING.TOOLTIPS.GLOBAL_BENCHMARKING_TOGGLE,
        },
      ],
    },
    {
      key: "local_region_area",
      isHeadless: true,
      className: "grid-full-width",
      // form fields
      formControls: [
        {
          name: "Local Region Area",
          isRequired: false,
          component: "Toggle",
          key: "local_region_factor",
          label: translate("Local Region Area"),
          value: isLocalRegionFactorEnabled,
          set: setIsLocalRegionFactorEnabled,
          icon: "fas fa-question-circle",
          iconToolTip: MASTER_BENCHMARKING.TOOLTIPS.LOCAL_REGION_AREA_TOGGLE,
        },
      ],
    },
  ];

  return forms;
}

export function getFormsForBenchmarkCreateAnalysisProjectSelection({
  selectedBenchmarkIds,
  benchmarkSelection,
}) {
  const selectedBenchmarksAsForm = {
    key: "selected_projects",
    label: `${tranStr("Selected Projects")} (${selectedBenchmarkIds.length})`,
    icon: "fas fa-tasks",
    className: "grid-full-width",
    formControls: [
      {
        key: "benchmark-selection",
        label: "",
        value: (
          <BenchmarkMiniTable
            selectedBenchmarkIds={selectedBenchmarkIds}
            benchmarkSelection={benchmarkSelection}
          />
        ),
      },
    ],
  };

  return [selectedBenchmarksAsForm];
}

export function prepareOnBenchmarkAnonymityToggle({
  refBenchmarks,
  setRefBenchmarks,
  restBenchmarks,
  setRestBenchmarks,
}) {
  return function onBenchmarkAnonymityToggle(props) {
    // these props are being fed from BenchmarkAnonymity > input > onChange
    const { comparisonId, isAnonymous } = props;

    anonymizeBenchmark({
      benchmarks: refBenchmarks,
      setBenchmarks: setRefBenchmarks,
      comparisonId,
      isAnonymous,
      serialNumberStart: 1,
    });

    anonymizeBenchmark({
      benchmarks: restBenchmarks,
      setBenchmarks: setRestBenchmarks,
      comparisonId,
      isAnonymous,
      serialNumberStart: 2,
    });
  };
}

function anonymizeBenchmark({
  benchmarks,
  setBenchmarks,
  comparisonId,
  isAnonymous,
  serialNumberStart,
}) {
  const benchmarks_clone = deepClone(benchmarks);

  benchmarks_clone.forEach((benchmark, index) => {
    if (benchmark.id === comparisonId) {
      // anonymize benchmark name
      if (isAnonymous) {
        //preserve
        benchmark.project_name_original = benchmark.project_name;
        benchmark.project_name_short_original = benchmark.project_name_short;
        // apply
        const anonymizedName = `Benchmark ${index + serialNumberStart}`;
        benchmark.project_name = benchmark.project_name_short = anonymizedName;
        benchmark.isAnonymous = true;
      }
      // revert benchmark name
      else {
        // restore
        benchmark.project_name = benchmark.project_name_original;
        benchmark.project_name_short = benchmark.project_name_short_original;
        benchmark.isAnonymous = false;
      }
    }
  });

  setBenchmarks(benchmarks_clone);
}

export function linkBenchmarks({ thisBenchmark, benchmarks }) {
  if (thisBenchmark) {
    thisBenchmark.linked_benchmarks = benchmarks;
    thisBenchmark.linkedBenchmarksIds = benchmarks.map((b) => ({
      id: "",
      benchmarkId: b.id,
      isAnonymous: !!b.isAnonymous,
    }));
  }
}

export async function saveBenchmarkAnalysis({ item }) {
  // here item is of type BenchmarkCreateAnalysisPersistence

  const { user } = item;

  const errorMessage = {
    text: validateBenchmarkAnalysisItem({ item }),
    type: "",
  };

  if (errorMessage.text) {
    return { response: null, errorMessage };
  }

  const portfolio = await addPortfolioForBenchmarkAnalysis(user.id, item);
  const response = { ok: false };

  // verify the response
  if (portfolio.name === item.title && portfolio.id) {
    response.ok = true;
  }

  return { response, errorMessage };
}

function validateBenchmarkAnalysisItem({ item }) {
  const { isConfigurationComplete, isBenchmarkSelectionComplete, title } = item;

  if (!isConfigurationComplete) {
    return MASTER_BENCHMARKING.WARNINGS.CONFIGURATION_INCOMPLETE;
  }

  if (!isBenchmarkSelectionComplete) {
    return MASTER_BENCHMARKING.WARNINGS.BENCHMARK_SELECTION_INCOMPLETE;
  }

  const isTitleSpecified =
    title && title !== MASTER_BENCHMARKING.TITLE_PLACEHOLDER;

  if (!isTitleSpecified) {
    return MASTER_BENCHMARKING.WARNINGS.ITEM_TITLE_NOT_SPECIFIED;
  }

  return "";
}

export async function deleteBenchmarkAnalysis({ item }) {
  // here item is of type BenchmarkCreateAnalysisPersistence

  const { id } = item;

  const isDeletionSuccessful = await deletePortfolioOfBenchmarkAnalysis(id);
  const response = { ok: isDeletionSuccessful };

  return { response };
}

export async function getPreviouslySavedBenchmarkAnalysisItems(user) {
  const portfolios = await getPortfoliosOfBenchmarkAnalysis();

  const benchmarkAnalysisItems = portfolios.map(
    ({ id, name, projectIds, details }) =>
      new BenchmarkCreateAnalysisPersistence({
        user,
        id,
        title: name,
        configuration: new BenchmarkCreateAnalysisConfiguration({ ...details }),
        selectedBenchmarkIds: projectIds,
        benchmarkSelection: [],
        isConfigurationComplete: true,
        isBenchmarkSelectionComplete: true,
        isGenerateReady: true,
      })
  );

  return benchmarkAnalysisItems;
}

export function getBenchmarkIds({ selectedBenchmarkIds }) {
  const benchmarkIds = selectedBenchmarkIds.map((id) => ({
    benchmark_id: `${id}`,
  }));

  return benchmarkIds;
}

function takeScreenshot() {
  captureScreenshot({
    selector: "div.master-benchmarking",
    filenamePrefix: "benchmark-analysis-screenshot",
    margin: 20,
    ignoreSelectors: [
      "div.benchmarking-table-anonymity",
      "div.estimate-export-button",
    ],
  });
}

export function getBenchmarksRegionCountryList(benchmarks, LFI) {
  const regionCountryList = [];
  benchmarks.forEach((benchmark) => {
    const selectedLFI = LFI?.find((x) => x.city === benchmark.city);

    let region = null;
    let country = null;

    if (!isEmpty(selectedLFI)) {
      region = selectedLFI.region;
      country = selectedLFI.country;
    }

    if (region && country) {
      regionCountryList.push({
        region,
        country,
      });
    }
  });
  return regionCountryList;
}
