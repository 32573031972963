import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";

import { addProjectData, addBenchmarksPortfolio } from "./ProjectAPI.functions";
import {
  addProjectCostPlanData,
  addProjectsCostPlanning,
  addPortfolioCostPlanning,
} from "./ProjectCostPlanAPI";

import {
  addProjectsPostContract,
  addProjectCostReportStats,
} from "./ProjectPostContractAPI";

import { addProjectUsers } from "../permissions/PermissionsAPI";
import { addCompanyData } from "../admin/CompanyAPI";
import { portAPIs } from "../api-endpoints/PortfolioEndpoints";
import { projectAPIs } from "../api-endpoints/ProjectEndpoints";
import { projectsAdaptor } from "../api-adaptors/ProjectAdaptor";
import isEmpty from "../../validation/is-empty";

// Get a single project by project ID
export async function getProject(project_id) {
  // PROJECT API
  const url = projectAPIs().get_project + "/" + project_id;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let response = await fetch(url, config);
  if (response.ok) {
    const projects = await projectsAdaptor(response);
    // Get Users PROJECT data
    const project1 = await addProjectUsers(projects[0]);
    // Get Company PROJECT data
    const projectData0 = await addCompanyData([project1]);
    // Get Cost Planning PROJECT data
    const projectData1 = await addProjectCostPlanData([projectData0[0]]);
    // We only need the first one because it's a single project
    response = projectData1[0];
  } else {
    console.log("Get Project Error");
    response = [];
  }

  return response;
}

// Get all Projects available
export async function getAllProjects() {
  const url = projectAPIs().get_all_projects;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Admin Projects error";

  let response = await fetch(url, config);
  if (response.ok) {
    // Project Data
    const projects = await projectsAdaptor(response);
    response = projects;
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

// Get all Project IDs available to the user in All Projects
export async function getProjectDetailByUser(abortSignal) {
  // PERMISSION API
  const url = projectAPIs().GetProjectDetailByUser;
  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  if (!isEmpty(abortSignal)) {
    config.signal = abortSignal;
  }

  let response = await fetch(url, config);

  if (response.ok) {
    const projects = await projectsAdaptor(response);
    // Get Company PROJECT data
    const projectDataB = await addCompanyData(projects);
    response = projectDataB;
  } else {
    console.log("getProjectsByAuthPermission Error");
    response = [];
  }

  return response;
}

// Get all Projects in a given Portfolio
export async function getPortfolioProjects(portfolioID, abortSignal) {
  if (isEmpty(portfolioID)) {
    return [];
  }

  // PROJECT API
  const url = portAPIs().Get + "/" + portfolioID;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  if (!isEmpty(abortSignal)) {
    config.signal = abortSignal;
  }

  let response = await fetch(url, config);

  if (response.ok) {
    const portfolio = await response.json();
    const projectIDs = portfolio.projectIds.map((x) => {
      return {
        project_id: x,
      };
    });
    // Get Project data
    const projectData0 = await addProjectData(projectIDs);

    response = projectData0;
  } else {
    console.log("getPortfolioProjects Error");
    response = [];
  }

  return response;
}

export function useProject(project_id, user_id) {
  const [projectLoading, setLoading] = useState(true);
  const [project, setProject] = useState([]);
  useEffect(() => {
    if (project_id) {
      setLoading(true);
      getProject(project_id)
        .then((projectRes) => {
          setProject({ ...projectRes, set: setProject, get: getProject });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [project_id, user_id]);

  return {
    project,
    projectLoading,
    error: null,
  };
}

export async function projectUpdate(project) {
  let projectUpdateData = await project.get(project.id);
  project.set({
    ...projectUpdateData,
    set: project.set,
    get: project.get,
  });
}

export async function getProjectsByProjectIDs(projectIDs) {
  if (isEmpty(projectIDs)) {
    return [];
  }

  // PROJECT API
  const url = projectAPIs().get_projects_by_project_ids;

  const config = {
    method: "POST",
    body: JSON.stringify(projectIDs),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    const projects = await projectsAdaptor(response);
    response = projects;
  } else {
    console.log("get_projects_by_project_ids Error");
    response = await response.json();
    console.log(response);
  }

  return response;
}

// Get all Projects for SystemDashboard
export async function getAllSystemDashboardProjects() {
  const url = projectAPIs().get_all_projects;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get System Dashboard Projects error";

  let response = await fetch(url, config);
  if (response.ok) {
    // Project Data
    const projects = await projectsAdaptor(response);
    // Get Cost Planning PROJECT data
    const projectDataC = await addProjectCostPlanData(projects);
    // Get Cost Planning Service Data
    const projectDataD = await addProjectsCostPlanning(projectDataC);
    // Get Post Contract PROJECT data
    const projectDataE = await addProjectCostReportStats(projectDataD);

    response = projectDataE;
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}

export function useSystemDashboardProject() {
  const [projectLoading, setLoading] = useState(true);
  const [project, setSystemDashboardproject] = useState([]);
  useEffect(() => {
    setLoading(true);
    getAllSystemDashboardProjects()
      .then((projects) => {
        setLoading(false);
        setSystemDashboardproject(projects);
      })
      .catch((e) => {});
  }, []);

  return {
    projectLoading,
    project,
    setSystemDashboardproject,
    error: null,
  };
}

// Get all Project IDs available to the user in All Projects
export async function getProjectsCostPlanning(
  projects,
  selectedPortfolio,
  abortSignal
) {
  // Get Cost Planning PROJECT data in all cases
  const projectDataC = await addProjectCostPlanData(projects, abortSignal);

  // In a particular portfolio we want to get extra data for the dashboard
  if (selectedPortfolio.id !== "all-projects") {
    const projectDataD = await addPortfolioCostPlanning(
      projectDataC,
      abortSignal
    );
    // Get Linked Benchmarks for the Cost Plans
    await addBenchmarksPortfolio(projectDataD, abortSignal);
    return projectDataD;
  } else {
    // If it is the all projects view we simply want to get the basic cost planning
    const projectDataD = await addProjectsCostPlanning(
      projectDataC,
      abortSignal
    );
    return projectDataD;
  }
}

// Get all Project IDs available to the user in All Projects
export async function getProjectsPostContract(
  projects,
  // selectedPortfolio,
  abortSignal
) {
  // Get Post Contract Service Data
  const projectDataF = await addProjectsPostContract(projects, abortSignal);

  return projectDataF;
}
