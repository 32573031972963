import React from "react";
import "./ToggleControlSwitch.css";

export default function ToggleControlSwitch(props) {
  const { style, label, checked = false, disabled = false, onChange } = props;
  return (
    <div className="toggle-control-switch-container" style={style}>
      <span
        className={`toggle-control-switch-label ${disabled ? "disabled" : ""}`}
      >
        {label}
      </span>
      <label className="toggle-control-switch">
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span className="slider round" />{" "}
      </label>
    </div>
  );
}
