import React, { useState } from "react";

import isEmpty from "../../validation/is-empty";
import { determineSubLocation } from "../estimate-commentary/EstimateCommentary.functions";
import { hasRoles } from "../../utils/roles";

import "./ValueEngineeringControl.css";

export default function ValueEngineeringControl(props) {
  const { project } = props;
  const { user } = props;
  const { CP } = props;

  const { setModal, setIsModalReadonly } = props;

  const [isOpen, setIsOpen] = useState(false);

  let classType = isOpen
    ? "estimate-edit-button-clicked"
    : "estimate-edit-button";

  if (isEmpty(user)) {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <>
      <button
        id={"value-engineering-control-open-button"}
        className={classType}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="fas fa-cog"></i>
      </button>
      <Control
        project={project}
        user={user}
        CP={CP}
        isOpen={isOpen}
        setModal={setModal}
        setIsModalReadonly={setIsModalReadonly}
      />
    </>
  );
}

export function Control(props) {
  const { project } = props;
  const { user } = props;
  const { CP } = props;
  const { isOpen } = props;

  const { setModal, setIsModalReadonly } = props;

  // No Cost Plans
  if (isEmpty(CP)) {
    return null;
  }

  // Hide if not Commentary
  let subLocation = determineSubLocation();
  if (subLocation !== "value-management") {
    return null;
  }

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  const classType = isOpen
    ? "value-engineering-control"
    : "value-engineering-control-hidden";

  return (
    <div className={classType}>
      <div className="value-engineering-edit-wrapper">
        <button
          id={"button-add-value-engineering"}
          className="estimate-edit-button"
          onClick={() => {
            setModal(true);
            setIsModalReadonly(false);
          }}
          title="Add VM Item"
        >
          <i className="fas fa-pencil-alt"></i>
        </button>
      </div>
    </div>
  );
}
